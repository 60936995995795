import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import _get from "lodash/get"
import useIsSsr from "@/hooks/useIsSsr"
import Modal from "@/components/core/Modal/Modal"
import Button from "@/components/core/Button/Button"

import { validateRecaptcha } from "@/components/core/ReCaptcha/ReCaptcha"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"

import { selectAuthState, setPersona } from "@/store/features/authSlice"

import { getUserPersona, sanitizeInnerHtml } from "@/utils/helper"

import { showToast, selectGenericState } from "@/store/features/genericSlice"

import FriendsAndFamilyView from "@/components/FriendsAndFamily/v1/FriendsAndFamilyView"
import useFriendsAndFamilyi18n from "@/i18n/useFriendsAndFamilyi18n"

import CONSTANTS, {
  FRIENDS_AND_FAMILY_QUERRY_SELECTOR_URL,
  FRIENDS_AND_FAMILY_API_URL_ENDPOINT,
} from "@/constants"
import { redirectionToContentPage } from "@/components/FriendsAndFamily/v1/friendsAndFamilyHelper"
import {
  addDataLayerContinueShopping,
  addDataLayerModelClose,
} from "@/components/FriendsAndFamily/v1/FriendsAndFamilyView/analytics/index"

const FriendsAndFamily = props => {
  const { data: authorData } = props
  const { emailBrand = "" } = authorData
  const { isAuth } = useSelector(selectAuthState)
  const { pageIsInteractive } = useSelector(selectGenericState)
  const isSsr = useIsSsr()
  const i18n = useFriendsAndFamilyi18n({
    authorData,
  })

  const {
    successTitle = "",
    successMessage = "",
    continueShopping = "",
    genericError = "",
  } = i18n

  const [showSuccess, setShowSuccess] = useState(false)
  const [applyForm, setApplyForm] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [legalPageLinks, setLegalPageLinks] = useState("")
  const [persona, setUserPersona] = useState("")
  const [nodeList, setNodeList] = useState([])
  const [btnLoaded, setBtnLoaded] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    redirectionToContentPage()
  }, [])

  useEffect(() => {
    getConfig().then(config => {
      const legalPageLinks = _get(config, "legalPageData.map", {})

      setLegalPageLinks(legalPageLinks)
      setUserPersona(getUserPersona())
    })
  }, [])

  useEffect(() => {
    if (!showAlertModal || !showSuccess) {
      setBtnLoaded(false)
    } else {
      setBtnLoaded(true)
    }
  }, [showAlertModal || showSuccess])

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      if (!btnLoaded) {
        setTimeout(function () {
          getNodeList()
        }, 500)
      }
    }
  }, [isSsr, pageIsInteractive, btnLoaded, nodeList])

  const getNodeList = () => {
    const elements = document.querySelectorAll(
      FRIENDS_AND_FAMILY_QUERRY_SELECTOR_URL
    )
    if (elements) {
      setNodeList(elements)
      showMembershipPopupHandler()
    }
  }

  const showMembershipPopupHandler = () => {
    nodeList.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        setShowAlertModal(true)
        setShowSuccess(false)
        setApplyForm(true)
      })
    })
  }

  const showToaster = msg => {
    dispatch(
      showToast({
        message: msg,
        isVisible: true,
      })
    )
  }

  const handleSubmit = (payload, success, error, analyticsCB) => {
    const { firstName, lastName, email, zipcode, groupCode, kbSubscription } =
      payload
    setLoading(true)
    validateRecaptcha()
      .then(res => {
        if (res.success) {
          apim
            .post(FRIENDS_AND_FAMILY_API_URL_ENDPOINT, {
              firstName: firstName,
              lastName: lastName,
              email: email,
              zipcode: zipcode,
              groupCode: groupCode,
              emailBrand: emailBrand ?? "",
              subscribeKohlerKBConsumer: kbSubscription,
            })
            .then(() => {
              setLoading(false)
              setShowAlertModal(false)
              setShowSuccess(true)
              setApplyForm(false)

              if (isAuth) {
                if (persona != CONSTANTS.FF_PERSONA) {
                  setUserPersona(CONSTANTS.FF_PERSONA)
                  dispatch(setPersona(CONSTANTS.FF_PERSONA))
                }
              }
              success()
            })
            .catch(() => {
              setLoading(false)
              error()
            })
        } else {
          setLoading(false)
          analyticsCB?.("failure", genericError)
          showToaster(genericError)
        }
      })
      .catch(() => {
        analyticsCB?.("failure", genericError)
      })
  }

  const handleClose = e => {
    setShowAlertModal(false)
    setShowSuccess(false)
    addDataLayerModelClose(e)
  }

  const handleContinueShopping = e => {
    e.preventDefault()
    setShowAlertModal(false)
    setShowSuccess(false)
    addDataLayerContinueShopping(e)
  }

  return (
    <Modal
      showModal={showAlertModal || showSuccess}
      onModalClose={handleClose}
      labelledBy="ff-modal-title"
      describedBy="ff-modal-description"
      lastElem="ff-last-element"
    >
      {applyForm ? (
        <FriendsAndFamilyView
          i18n={i18n}
          onSubmit={handleSubmit}
          closeModal={() => setShowAlertModal(false)}
          showModal={showAlertModal}
          loading={loading}
          legalPages={legalPageLinks}
        />
      ) : null}
      {showSuccess ? (
        <div
          id="success-modelId"
          tabIndex="0"
          className="success-message-content"
        >
          <section className="success-modal-header">
            <h4>{successTitle}</h4>
            <div className="success-message-model">
              <span>{sanitizeInnerHtml(successMessage)}</span>
            </div>
          </section>
          <Button
            role="button"
            type="black"
            label={continueShopping}
            onClick={handleContinueShopping}
            className="friends-family-button"
          />
        </div>
      ) : null}
    </Modal>
  )
}
export default FriendsAndFamily
