import { FRIENDS_AND_FAMILY_DATA_LAYER } from "@/components/FriendsAndFamily/v1/FriendsAndFamilyView/analytics/constants"

const friendsAndFamily = `${FRIENDS_AND_FAMILY_DATA_LAYER.FRIENDS_AND_FAMILY_CONGRATS}:${FRIENDS_AND_FAMILY_DATA_LAYER.CONTINUE_SHOPPING}`
const friendsAndFamilyCongrtas = `${FRIENDS_AND_FAMILY_DATA_LAYER.FRIENDS_AND_FAMILY_CONGRATS}`

const getEventInfo = (type, link, needsJSON = false) => {
  const eventInfo = {
    eventAction: "friends-and-family-program:group code:" + type,
    eventName: "friends-and-family-program:group code:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    formName: "kohler friends and family program form",
    internalLinkName: type,
    internalLinkPosition: "friends-and-family-program:group code form",
    internalLinkType: "friends-and-family-program:" + type,
    internalLinkZoneName: "friends-and-family-program:overlay",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 && link.indexOf("mailto:") === -1
          ? window.location.origin
          : "") + link
      : "n/a",
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

const addSubmitAnalytics = (
  status = "",
  message = "",
  groupCode = "",
  agreeTerms = {}
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState?.("page") || {}
  const eventMsg = message
    ? message.toLowerCase().replace(/[^a-zA-Z ]/g, "")
    : "n/a"
  const eventInfo = {
    ...getEventInfo("submit", null, true),
    eventStatus: status,
    eventMsg,
    groupCode,
    termsAndConditions: agreeTerms?.terms,
    getInspringIdeas: agreeTerms?.kbSubscription,
  }

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

const getRecaptchaAnalytics = {
  pageTitle: "friends-and-family-program:group code",
  internalLinkPosition: "friends-and-family-program:group code form",
  internalLinkType: "friends-and-family-program",
  internalLinkTypeWithType: "friends-and-family-program",
}

const addDataLayerModelClose = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${friendsAndFamilyCongrtas}:close`,
    eventMsg: "n/a",
    eventName: `${friendsAndFamilyCongrtas}:close`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "close",
    internalLinkPosition:
      FRIENDS_AND_FAMILY_DATA_LAYER.FRIENDS_AND_FAMILY_PROGRAM,
    internalLinkType: friendsAndFamilyCongrtas,
    internalLinkURL: window.location.href,
    internalLinkZoneName: friendsAndFamilyCongrtas,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const addDataLayerContinueShopping = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: friendsAndFamily,
    eventMsg: "n/a",
    eventName: friendsAndFamily,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: FRIENDS_AND_FAMILY_DATA_LAYER.CONTINUE_SHOPPING,
    internalLinkPosition:
      FRIENDS_AND_FAMILY_DATA_LAYER.FRIENDS_AND_FAMILY_PROGRAM,
    internalLinkType: `${friendsAndFamilyCongrtas}`,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${friendsAndFamilyCongrtas}`,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}, event = "cmp:click") => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
  })
}

export {
  getEventInfo,
  addSubmitAnalytics,
  getRecaptchaAnalytics,
  addDataLayerModelClose,
}
