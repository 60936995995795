import React, { useEffect, useState } from "react"
import Head from "next/head"
import DOMPurify from "isomorphic-dompurify"
import { EMPTY_PIXEL } from "@/constants"
import { getProductStatus } from "@/utils/helper"

const NextHead = props => {
  const {
    fullUrl = "",
    psKey = "",
    psCountry = "",
    psLanguage = "",
    metaLanguage = "",
    pdpData: {
      ProductBrandName_s: productName = "",
      productName_s: partsName = "",
      // ProductDescriptionProductShort_s: productDescription = "",
      "Color.SKU.Details_ss": skuDetails = [],
      productIsInStock_b: available = true,
      masterVariantDataResponse: variants = [],
      productName_s: productFullName = "",
      metaDescription_s: productMetaDescription = "",
      productRegionAllVariantDiscontinued_b: allVariantDiscontinued = false,
      RegionOnBackOrder_s: backorder = "",
    } = {},
    pdpData = {},
    initialImageFullUrl = "",
    isPart = false,
    skuId = "",
    siteName = "",
    isBundleProduct = false,
    persona = "GST",
    config = {},
  } = props

  const { internationalization: { currencyCode = "USD" } = {} } = config
  const selectedSkuDetails =
    skuDetails?.find(item => item.includes(skuId)) ?? ""
  const selectSkuList = selectedSkuDetails?.split("|") ?? []
  const selectedSku = selectSkuList[selectSkuList.length - 1] ?? ""

  const getVariantImage = (variantImages, tmpSkuId) => {
    let initialImgUrl = ""
    let initialImageFullUrl = ""
    let variantImageLabels = []
    let variantImageURLs = []
    if (variantImages?.length) {
      const selVarImageUrls = []
      const selVarImageLabels = []
      variantImages.forEach(img => {
        const imgDetail = img.split("|")
        if (tmpSkuId === imgDetail[0]) {
          selVarImageLabels.push(imgDetail[4] ?? "")
          selVarImageUrls.push(
            imgDetail[imgDetail.length - 1]
              ? imgDetail[imgDetail.length - 1]
              : EMPTY_PIXEL
          )
        } else if (!variants.find(v => v === imgDetail[0])) {
          variantImageLabels.push(imgDetail[4] ?? "")
          variantImageURLs.push(
            imgDetail[imgDetail.length - 1]
              ? imgDetail[imgDetail.length - 1]
              : EMPTY_PIXEL
          )
        }
      })
      variantImageLabels = [...selVarImageLabels, ...variantImageLabels]
      variantImageURLs = [...selVarImageUrls, ...variantImageURLs]
      if (variantImageURLs.length < 1) {
        variantImageLabels.push(tmpSkuId)
        variantImageURLs.push(EMPTY_PIXEL)
      }
    } else {
      variantImageLabels.push(tmpSkuId)
      variantImageURLs.push(EMPTY_PIXEL)
    }
    const assetIdArray = variantImageURLs
      ? variantImageURLs[0].split("PAWEB/")
      : [""]
    initialImgUrl = assetIdArray.length > 1 ? assetIdArray[1] : ""
    initialImageFullUrl =
      variantImageURLs.find(image => image.includes(initialImgUrl)) ?? ""
    return initialImageFullUrl
  }

  const getVariantsSchema = () => {
    let variantsSchema = []
    if (variants.length > 1) {
      variantsSchema = variants.map(variant => {
        const skuId = variant?.sku_s ?? ""
        const selectedSkuDetails =
          skuDetails?.find(item => item.includes(skuId)) ?? ""
        const selectSkuList = selectedSkuDetails?.split("|") ?? []
        const selectedSku = selectSkuList[selectSkuList.length - 1] ?? ""
        return {
          "@type": "Product",
          sku: selectedSku,
          gtin: variant?.SKUUPCCode_s ?? "",
          image: getVariantImage(
            variant["productImages.labelWithUrl_ss"],
            variant.sku_s
          ),
          name: variant?.productName_s ?? "",
          description: variant?.metaDescription_s ?? "",
          color: variant?.ColorFinish_s ?? "",
          offers: {
            "@type": "Offer",
            url: `${fullUrl}?skuId=${variant?.sku_s ?? ""}`,
            priceCurrency: currencyCode,
            price: variant?.[`priceList.${persona}.finalPrice_d`] ?? 0,
            itemCondition: "https://schema.org/NewCondition",
            availability: variant.productIsInStock_b
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock",
          },
        }
      })
    }
    return variantsSchema
  }

  const getSkuWithoutColorFinsih = (skuId = "") => {
    return skuId.substring(0, skuId.lastIndexOf("-"))
  }

  const productSchema =
    variants.length > 1
      ? [
          {
            "@context": "https://schema.org",
            "@type": "ProductGroup",
            "@id": fullUrl,
            url: fullUrl,
            additionalType: isPart ? "Service Part" : "",
            name: isPart
              ? partsName
              : isBundleProduct
              ? partsName
              : productFullName,
            image: initialImageFullUrl,
            description: isBundleProduct ? partsName : productMetaDescription,
            sku: getSkuWithoutColorFinsih(
              isBundleProduct ? skuId : selectedSku
            ),
            gtin:
              variants?.filter(variant => variant.masterVariant_b)?.[0]
                ?.SKUUPCCode_s ?? "",
            brand: {
              "@type": "Brand",
              name: siteName,
            },
            productGroupID: getSkuWithoutColorFinsih(
              isBundleProduct ? skuId : selectedSku
            ),
            variesBy: ["https://schema.org/color"],
            hasVariant: getVariantsSchema(),
          },
        ]
      : {
          "@context": "https://schema.org",
          "@type": "Product",
          "@id": fullUrl,
          url: fullUrl,
          additionalType: isPart ? "Service Part" : "",
          name: isPart
            ? partsName
            : isBundleProduct
            ? partsName
            : productFullName,
          image: initialImageFullUrl,
          description: isBundleProduct ? partsName : productMetaDescription,
          sku: isBundleProduct ? skuId : selectedSku,
          gtin:
            variants?.filter(variant => variant.masterVariant_b)?.[0]
              ?.SKUUPCCode_s ?? "",
          brand: {
            "@type": "Brand",
            name: siteName,
          },
          offers: {
            "@type": "Offer",
            price: pdpData?.[`priceList.${persona}.finalPrice_d`] ?? 0,
            priceCurrency: currencyCode,
            availability: available
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock",
            itemCondition: "http://schema.org/NewCondition",
          },
        }

  return (
    <Head>
      <meta name="keywords" content="Kohler US Plumbing Bathroom Kitchen" />
      <meta
        property="og:price:amount"
        content={parseFloat(
          pdpData?.[`priceList.${persona}.finalPrice_d`] ?? 0
        ).toFixed(2)}
      />
      <meta property="og:price:currency" content={currencyCode || "USD"} />
      <meta
        property="og:availability"
        content={getProductStatus(allVariantDiscontinued, backorder, available)}
      />
      {metaLanguage ? (
        <meta
          property="og:locale"
          content={metaLanguage?.toLocaleLowerCase()}
        />
      ) : null}
      {fullUrl ? <link rel="canonical" href={fullUrl} /> : null}
      {psKey ? <meta name="ps-key" content={psKey} /> : null}
      {psCountry ? <meta name="ps-country" content={psCountry} /> : null}
      {psLanguage ? <meta name="ps-language" content={psLanguage} /> : null}
      {/* need to use dangerouslySetInnerHTML in the below block to prevent default encoding */}
      {productName || partsName ? (
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(JSON.stringify(productSchema)),
          }}
        />
      ) : null}
    </Head>
  )
}

export default NextHead
