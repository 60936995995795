import {
  ASSOCIATE_MEMBERSHIP,
  PRIVACY_POLICY,
} from "@/components/AssociateMembership/v1/analytics/constants"

const associateMembership = `${ASSOCIATE_MEMBERSHIP.INSIDER_PROGRAM}:${ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM}`

const getEventInfo = (isRetired, type, link, needsJSON = false) => {
  let extractedLink = ""
  if (link) {
    if (link.indexOf("http") === -1 && link.indexOf("mailto:") === -1) {
      extractedLink = window.location.origin + link
    } else {
      extractedLink = link
    }
  } else {
    extractedLink = "n/a"
  }

  const eventActionType = type === "don't have a kohler email?"
  const eventFor = isRetired ? "Ko" : "email"
  const eventAction = eventActionType
    ? `insider-program-associate-discount:group code:${type}`
    : `insider-program-associate-discount:${eventFor}:${type}`
  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventType: "click",
    eventMsg: "n/a",
    eventStatus: "n/a",
    formName: "kohler insider program form",
    internalLinkName: type,
    internalLinkPosition:
      "insider-program-associate-discount:" +
      (eventActionType ? "group code form" : "form"),
    internalLinkType: "insider-program-associate-discount:" + type,
    internalLinkZoneName: "insider-program-associate-discount:overlay",
    internalLinkURL: extractedLink,
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

const addDataLayerModelClose = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${associateMembership}:close`,
    eventName: `${associateMembership}:close`,
    eventMsg: "n/a",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "close",
    internalLinkPosition: associateMembership,
    internalLinkType: ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${associateMembership}:overlay`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const handleExternalLinkAnalytic = (e, link, title) => {
  e.preventDefault()
  const eventInfo = {
    clickInternalLinks:
      title === PRIVACY_POLICY.GOOGLE_PRIVACY ||
      title === PRIVACY_POLICY.GOOGLE_TERMS
        ? "false"
        : "true",

    eventAction: `${associateMembership}:${title?.toLowerCase()}`,
    eventMsg: "n/a",
    eventName: `${associateMembership}:${title?.toLowerCase()}`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: title?.toLowerCase(),
    internalLinkPosition: associateMembership,
    internalLinkType: `${
      ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM
    }:${title?.toLowerCase()}`,
    internalLinkURL: link,
    internalLinkZoneName: `${associateMembership}:overlay`,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const addAnalyticsForResendCode = (title, message) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${associateMembership}:${title}`,
    eventMsg: "n/a",
    eventName: `${associateMembership}:${title}`,
    eventStatus: "success",
    eventType: "navigation",
    internalLinkName: title,
    internalLinkPosition: associateMembership,
    internalLinkType: `${ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM}:${title}`,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${associateMembership}:overlay`,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const handleConfirmFailure = (title, message) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${associateMembership}:${title}`,
    eventMsg: message,
    eventName: `${associateMembership}:${title}`,
    eventStatus: "failure",
    eventType: "navigation",
    internalLinkName: title,
    internalLinkPosition: associateMembership,
    internalLinkType: `${ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM}:${title}`,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${associateMembership}:overlay`,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const handleConfirmSuccess = (title, message) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${associateMembership}:${title}`,
    eventMsg: "n/a",
    eventName: `${associateMembership}:${title}`,
    eventStatus: "success",
    eventType: "navigation",
    internalLinkName: title,
    internalLinkPosition: associateMembership,
    internalLinkType: `${ASSOCIATE_MEMBERSHIP.INSIDER_CONFIRM}:${title}`,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${associateMembership}:overlay`,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}, event = "cmp:click") => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
  })
}

export { getEventInfo, addDataLayerModelClose }
