import React, { useState } from "react"
import Button from "@/components/core/Button/Button"
import Input from "@/components/core/Input/Input"
import {
  addAnalyticsForResendCode,
  handleConfirmFailure,
  handleConfirmSuccess,
  handleExternalLinkAnalytic,
} from "@/components/AssociateMembership/v1/analytics/index"
import { PRIVACY_POLICY } from "@/components/AssociateMembership/v1/analytics/constants"

const Confirm = ({
  i18n = {},
  verifyCode = () => {},
  loading = false,
  resendCode = () => {},
  email = "",
  legalPages = {},
}) => {
  const [code, setCode] = useState("")
  const {
    errorCode = "",
    codeInvalidError = "",
    nameConfirm = "",
    descriptionConfirm = "",
    description1 = "",
    codeConfirm = "",
    resendCodeError = "",
    resendCode: i18nResendCode = "",
    protectedByGoogle = "",
    privacyPolicy = "",
    termsOfService = "",
    subjectAccessRequest = "",
  } = i18n

  const {
    privacyPolicy: legalPagesPrivacyPolicy,
    subjectAccessRequest: legalPagesSubjectAccessRequest,
    termsOfService: legalPagesTermsOfService,
  } = legalPages

  const [codeError, setCodeError] = useState({ show: false, message: "" })
  const clearError = () => {
    setCodeError({ show: false, message: "" })
  }

  const handleSubmit = e => {
    e.preventDefault()
    clearError()
    let valid = true
    if (!code) {
      setCodeError({ show: true, message: errorCode })
      valid = false
      handleConfirmFailure("confirm", errorCode.toLowerCase())
    }
    if (valid) {
      verifyCode(code, () =>
        setCodeError({ show: true, message: codeInvalidError })
      )
      handleConfirmSuccess("confirm", codeInvalidError.toLowerCase())
    }
  }

  return (
    <div className="associate-membership">
      <div className="associate-membership__header">
        <div className="associate-membership__title">{nameConfirm}</div>
      </div>
      <div className="associate-membership__description">
        {descriptionConfirm}
      </div>
      <div className="associate-membership__description1">{description1}</div>
      <Input
        id="code"
        type="text"
        value={code}
        maxLength={40}
        placeholder={`${codeConfirm}*`}
        label={`${codeConfirm}*`}
        onChange={e => setCode(e.target.value)}
        showError={codeError.show}
        errorMessage={codeError.message}
      />
      <div className="associate-membership__terms">
        <div>
          <span
            onClick={() => {
              resendCode(email, () =>
                setCodeError({
                  show: true,
                  message: resendCodeError,
                })
              )
              addAnalyticsForResendCode(i18nResendCode.toLowerCase())
            }}
          >
            {i18nResendCode}
          </span>
        </div>
      </div>
      <div className="associate-membership__button">
        <Button
          type="black"
          label="Confirm"
          flexible
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
      <div className="my-3">
        <span className="protectedByGoogle">{`${protectedByGoogle} `}</span>
        <a
          className="policies"
          href={legalPagesPrivacyPolicy}
          onClick={e =>
            handleExternalLinkAnalytic(
              e,
              legalPagesPrivacyPolicy,
              PRIVACY_POLICY.GOOGLE_PRIVACY
            )
          }
        >
          {privacyPolicy}
        </a>
        <span className="protectedByGoogle"> and </span>
        <a
          className="policies"
          href={legalPagesTermsOfService}
          onClick={e =>
            handleExternalLinkAnalytic(
              e,
              legalPagesTermsOfService,
              PRIVACY_POLICY.GOOGLE_TERMS
            )
          }
        >
          {termsOfService}
        </a>
        <span className="protectedByGoogle"> apply</span>
      </div>
      <div className="associate-membership__policies">
        <a
          href={legalPagesPrivacyPolicy}
          onClick={e =>
            handleExternalLinkAnalytic(
              e,
              legalPagesPrivacyPolicy,
              privacyPolicy
            )
          }
        >
          {privacyPolicy}
        </a>
        <a
          href={legalPagesSubjectAccessRequest}
          onClick={e =>
            handleExternalLinkAnalytic(
              e,
              legalPagesSubjectAccessRequest,
              subjectAccessRequest
            )
          }
        >
          {subjectAccessRequest}
        </a>
        <a
          href={legalPagesTermsOfService}
          onClick={e =>
            handleExternalLinkAnalytic(
              e,
              legalPagesTermsOfService,
              termsOfService
            )
          }
        >
          {termsOfService}
        </a>
      </div>
    </div>
  )
}

export default Confirm
